import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["modal", "overlay", "flashContainer"];

  connect() {
    this.closeFunction = () => {
      this.close();
      this.showFlashMessage("施策を追加しました", "success");
    };
    document.addEventListener("modal:close", this.closeFunction);
  }

  open() {
    this.modalTarget.classList.add("show");
    this.modalTarget.style.display = "block";
    this.overlayTarget.style.display = "block";
    this.modalTarget.removeAttribute("aria-hidden");
    this.modalTarget.setAttribute("aria-modal", true);
    this.modalTarget.setAttribute("role", "dialog");
  }

  close() {
    this.modalTarget.classList.remove("show");
    this.modalTarget.style.display = "none";
    this.overlayTarget.style.display = "none";
    this.modalTarget.setAttribute("aria-hidden", true);
    this.modalTarget.removeAttribute("aria-modal");
    this.modalTarget.removeAttribute("role");
  }

  showFlashMessage(message, type) {
    const flashMessage = document.createElement("div");
    flashMessage.className = `alert alert-${type}`;
    flashMessage.role = "alert";
    flashMessage.textContent = message;

    this.flashContainerTarget.appendChild(flashMessage);
    setTimeout(() => {
      flashMessage.remove();
    }, 3000);
  }
}