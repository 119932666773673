import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { id: Number, url: String }

  connect() {
    this.flexCells = document.querySelectorAll('.flex-cell.record');
  }

  displayForm() {
    document.getElementById(`ticket_edit_${this.idValue}`).src = this.urlValue;
  }

  changeBorderColor(event) {
    if (['SPAN', 'A'].includes(event.target.tagName)) return;

    const targetIsJustified = event.target.classList.contains('justify-content-between');
    const targetElement = targetIsJustified ? event.target.parentElement : event.target;
    const hasBorder = ['2px solid rgb(64, 158, 255)', '2px solid #409eff'].includes(targetElement.style.border);

    if (hasBorder) {
        this.displayForm();
    } else {
      this.flexCells.forEach(cell => cell.style.border = '1px solid #d8d8d8');
      if(event.target.classList.contains('justify-content-between')) {
        event.target.parentElement.style.border = '2px solid #409eff';
      } else {
        event.target.style.border = '2px solid #409eff';
      }
    }
  }

  changeColor() {
    const frame = document.getElementById(`ticket_edit_${this.idValue}`);
    const cells = frame.querySelectorAll('.flex-cell.record');
    cells.forEach(cell => cell.style.backgroundColor = '#fafafa');
    cells.forEach(cell => cell.style.cursor = 'pointer');
  }

  resetColor() {
    const frame = document.getElementById(`ticket_edit_${this.idValue}`);
    const cells = frame.querySelectorAll('.flex-cell.record');
    cells.forEach(cell => cell.style.backgroundColor = '#ffffff'); 
  }
}