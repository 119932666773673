import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    connect() {
        this.clickOutsideHandler = this.submitOutside.bind(this);
        document.addEventListener("click", this.clickOutsideHandler);
    }

    disconnect() {
        document.removeEventListener("click", this.clickOutsideHandler);
    }

    submitOutside(event) {
        if (!['INPUT', 'SELECT'].includes(event.target.tagName)) {
            const form = this.element.querySelector("form");
            if (form) {
                const submitEvent = new CustomEvent('submit', {
                    bubbles: true, 
                    cancelable: true 
                });
                form.dispatchEvent(submitEvent);
                if (submitEvent.defaultPrevented) {
                    form.requestSubmit(); 
                }
            }
        }
    }
}
